<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.statistikAustria.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.statistikAustria.list.title"></app-i18n>
      </h1>

      <app-statistik-austria-list-toolbar></app-statistik-austria-list-toolbar>
      <app-statistik-austria-list-filter></app-statistik-austria-list-filter>
      <app-statistik-austria-list-table></app-statistik-austria-list-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import StatistikAustriaListFilter from '@/modules/statistik-austria/components/statistik-austria-list-filter.vue';
import StatistikAustriaListTable from '@/modules/statistik-austria/components/statistik-austria-list-table.vue';
import StatistikAustriaListToolbar from '@/modules/statistik-austria/components/statistik-austria-list-toolbar.vue';

export default {
  name: 'app-statistik-austria-list-page',

  components: {
    'app-statistik-austria-list-filter': StatistikAustriaListFilter,
    'app-statistik-austria-list-table': StatistikAustriaListTable,
    'app-statistik-austria-list-toolbar': StatistikAustriaListToolbar,
  },

  async beforeRouteLeave(to, from, next) {
    try {
      this.doReset();
      next();
    } catch (error) {
      next(false);
    }
  },

  methods: {
    ...mapActions({
      doReset: 'statistikAustria/list/doReset',
    }),
  },
};
</script>

<style></style>
