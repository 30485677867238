<template>
  <el-form
    :label-position="labelPosition"
    :label-width="labelWidthFilter"
    :model="model"
    @submit.native.prevent="doFilter"
    class="filter"
    ref="form"
  >
    <el-row type="flex">
      <el-col style="margin-bottom: -0.41px" :lg="8" :md="16" :sm="24">
        <el-form-item :label="fields.year.label" :prop="fields.year.name">
          <el-date-picker
            format="yyyy"
            type="year"
            v-model="model[fields.year.name]"
            :picker-options="disabledDates()"
          ></el-date-picker>
        </el-form-item>
      </el-col>
      <!--  <el-col :lg="2" :md="4" :sm="6">
        <el-form-item :label="fields.plan.label" :prop="fields.plan.name">
          <el-checkbox
            v-model="model[fields.plan.name]"
            :change="resetDatepickerVal"
          ></el-checkbox>
        </el-form-item>
      </el-col> -->
    </el-row>

    <div class="filter-buttons">
      <el-button
        :disabled="loading"
        @click="doFilter"
        icon="el-icon-fa-search"
        type="primary"
      >
        <app-i18n code="common.search"></app-i18n>
      </el-button>

      <el-button
        :disabled="loading"
        @click="doResetFilter"
        icon="el-icon-fa-undo"
      >
        <app-i18n code="common.reset"></app-i18n>
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { StatistikAustriaModel } from '@/modules/statistik-austria/statistik-austria-model';

const { fields } = StatistikAustriaModel;

export default {
  name: 'app-statistik-austria-list-filter',

  data() {
    return {
      model: {},
      pickerOptions: {
        disabledDate: this.disabledDate,
        onPick: this.pick,
      },
    };
  },
  /* watch: {
    pickerOptions(){
      if (this.model.plan) {
        return
      }
    }
  }, */

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      loading: 'statistikAustria/list/loading',
      filter: 'statistikAustria/list/filter',
    }),

    fields() {
      return fields;
    },
  },

  async mounted() {
    //return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'statistikAustria/list/doReset',
      doFetch: 'statistikAustria/list/doFetch',
    }),
    disabledDates() {
      return {
        disabledDate: (date) => {
          if (this.model.plan) {
            return date.getFullYear() < 2022;
          }
          return (
            date.getFullYear() < 2022 ||
            date.getFullYear() >= new Date().getFullYear()
          );
        },
      };
    },

    resetDatepickerVal() {
      this.model.year = null;
      this.$refs.form.fields.find((f) => f.prop == 'year').resetField();
    },

    async doResetFilter() {
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      const filter = { year: this.model.year.getFullYear() };
      if (this.model.plan) {
        filter.plan = true;
      }
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style></style>
