<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column :label="fields.name.label" :prop="fields.name.name">
        <template slot-scope="scope">
          {{ presenter(scope.row, 'name') }}
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.mitarbeiterNr.label"
        :prop="fields.mitarbeiterNr.name"
      >
        <template slot-scope="scope">
          {{ presenter(scope.row, 'mitarbeiterNr') }}
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.sex.label"
        :prop="fields.sex.name"
        align="center"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-one
            :label="fields.sex.label"
            :readPermission="fields.sex.readPermission"
            :navigatePermission="fields.sex.readPermission"
            :value="presenter(scope.row, 'sex')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.gebj.label"
        :prop="fields.gebj.name"
        align="center"
      >
        <template slot-scope="scope">
          <strong>{{ presenter(scope.row, 'gebj') }}</strong>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.staat.label"
        :prop="fields.staat.name"
        align="center"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-one
            :label="fields.staat.label"
            :readPermission="fields.staat.readPermission"
            :navigatePermission="fields.staat.readPermission"
            :value="presenter(scope.row, 'staat')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.ausb.label"
        :prop="fields.ausb.name"
        align="center"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-one
            :label="fields.ausb.label"
            :readPermission="fields.ausb.readPermission"
            :navigatePermission="fields.ausb.readPermission"
            :value="presenter(scope.row, 'ausb')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.taet1.label"
        :prop="fields.taet1.name"
        align="center"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-one
            :label="fields.taet1.label"
            :readPermission="fields.taet1.readPermission"
            :navigatePermission="fields.taet1.readPermission"
            :value="presenter(scope.row, 'taet1')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.taet2.label"
        :prop="fields.taet2.name"
        align="center"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-one
            :label="fields.taet2.label"
            :readPermission="fields.taet2.readPermission"
            :navigatePermission="fields.taet2.readPermission"
            :value="presenter(scope.row, 'taet2')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.fte.label"
        :prop="fields.fte.name"
        align="right"
      >
        <template slot-scope="scope">
          <strong>{{ presenter(scope.row, 'fte') }}</strong>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.verw.label"
        :prop="fields.verw.name"
        align="center"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-one
            :label="fields.verw.label"
            :readPermission="fields.verw.readPermission"
            :navigatePermission="fields.verw.readPermission"
            :value="presenter(scope.row, 'verw')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.fkt.label"
        :prop="fields.fkt.name"
        align="center"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-one
            :label="fields.fkt.label"
            :readPermission="fields.fkt.readPermission"
            :navigatePermission="fields.fkt.readPermission"
            :value="presenter(scope.row, 'fkt')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>
    </el-table>

    <!-- <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div> -->
  </div>
</template>

<script>
import { StatistikAustriaModel } from '@/modules/statistik-austria/statistik-austria-model';
import { mapGetters, mapActions } from 'vuex';

const { fields } = StatistikAustriaModel;

export default {
  name: 'app-statistik-austria-list-table',
  mounted() {
    this.doMountTable(this.$refs.table);
  },
  computed: {
    ...mapGetters({
      rows: 'statistikAustria/list/rows',
      count: 'statistikAustria/list/count',
      loading: 'statistikAustria/list/loading',
      pagination: 'statistikAustria/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
    }),
    fields() {
      return fields;
    },
  },
  methods: {
    ...mapActions({
      doChangeSort: 'statistikAustria/list/doChangeSort',
      doChangePaginationCurrentPage:
        'statistikAustria/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'statistikAustria/list/doChangePaginationPageSize',
      doMountTable: 'statistikAustria/list/doMountTable',
    }),
    presenter(row, fieldName) {
      return StatistikAustriaModel.presenter(row, fieldName);
    },
  },
};
</script>

<style></style>
